var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { createContext, useContext, useEffect, useState, useCallback, } from 'react';
import axios from 'axios';
import { DATA_REFRESH_INTERVAL } from '../constants/general';
import { useCurrentChain } from '../hooks/useChainStore';
import { getSdexUri } from '../utils/indexer';
import { decimalic } from '../utils/math';
const TokenPricesContext = createContext(undefined);
export const TokenPricesProvider = ({ children, }) => {
    const [prices, setPrices] = useState({});
    const [loading, setLoading] = useState(true);
    const currentChainId = useCurrentChain();
    const fetchTokenPrices = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        try {
            const response = yield axios.get(`${getSdexUri(currentChainId)}/tokens`);
            const { data } = response.data;
            if (data) {
                const prices = data.reduce((acc, tokenData) => {
                    acc[tokenData.address.toLowerCase()] = decimalic(tokenData.usdPrice).toString();
                    return acc;
                }, {});
                setPrices(prices);
            }
            else {
                setPrices({});
            }
        }
        catch (error) {
            console.error('Failed to fetch token prices:', error);
            setPrices({});
        }
        finally {
            setLoading(false);
        }
    }), [currentChainId]);
    useEffect(() => {
        fetchTokenPrices();
        const intervalId = setInterval(fetchTokenPrices, DATA_REFRESH_INTERVAL);
        return () => clearInterval(intervalId);
    }, [fetchTokenPrices]);
    return (React.createElement(TokenPricesContext.Provider, { value: { prices, loading } }, children));
};
export const useTokenPrices = () => {
    const context = useContext(TokenPricesContext);
    if (!context) {
        throw new Error('useTokenPrices must be used within a TokenPricesProvider');
    }
    return context;
};
